$(document).ready(function() {

    // console.log("remove footer");

    var list_emailupdate = document.getElementsByClassName("input-group");
    var list_footer = document.getElementsByClassName("footer-powered");
    var childDiv = document.getElementById("footer-subscribe-email");


    for (let item of list_footer) {
        item.style.visibility = 'hidden';
    }

    for (let item of list_emailupdate) {
        if (item.contains(childDiv)) {
            item.style.visibility = 'hidden';
        }
    }


});

if ($('a[href="/third_party_apps"]').length > 0 && $('a[href="/third_party_apps"]').closest('li').length > 0) {
    $('a[href="/third_party_apps"]').closest('li').remove()
}